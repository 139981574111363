<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="358" height="358" viewBox="0 0 358 358">
    <g id="Gruppe_3" data-name="Gruppe 3" transform="translate(-91 -95)">
      <g id="Rechteck_1" data-name="Rechteck 1" transform="translate(91 95)" fill="#fff" stroke="#707070" stroke-width="1">
        <rect width="358" height="358" rx="40" stroke="none"/>
        <rect x="0.5" y="0.5" width="357" height="357" rx="39.5" fill="none"/>
      </g>
      <line id="Linie_1" data-name="Linie 1" x2="257.995" transform="translate(140.865 139.445)" fill="none" stroke="#707070" stroke-width="3"/>
      <line id="Linie_1-2" data-name="Linie 1" x2="257.995" transform="translate(140.865 208.821)" fill="none" stroke="#707070" stroke-width="1"/>
      <line id="Linie_1-3" data-name="Linie 1" x2="257.995" transform="translate(140.865 278.198)" fill="none" stroke="#707070" stroke-width="1"/>
      <line id="Linie_1-4" data-name="Linie 1" x2="257.995" transform="translate(140.865 347.575)" fill="none" stroke="#707070" stroke-width="3"/>
      <line id="Linie_1-5" data-name="Linie 1" y2="252.484" transform="translate(380.432 121.016)" fill="none" stroke="#707070" stroke-width="3"/>
      <line id="Linie_1-6" data-name="Linie 1" y2="252.484" transform="translate(311.055 121.016)" fill="none" stroke="#707070" stroke-width="1"/>
      <line id="Linie_1-7" data-name="Linie 1" y2="252.484" transform="translate(241.678 121.016)" fill="none" stroke="#707070" stroke-width="1"/>
      <line id="Linie_1-8" data-name="Linie 1" y2="252.484" transform="translate(172.301 121.016)" fill="none" stroke="#707070" stroke-width="3"/>
      <text id="_2" data-name="2" transform="translate(193 198)" fill="#707070" font-size="49" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">2</tspan></text>
      <text id="_5" data-name="5" transform="translate(332 326)" fill="#707070" font-size="49" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">5</tspan></text>
      <text id="_3" data-name="3" transform="translate(332 261)" fill="#707070" font-size="49" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">3</tspan></text>
      <text id="Sudoku" transform="translate(186 424)" fill="#707070" font-size="47" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">Sudoku</tspan></text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "GameIcon"
}
</script>

<style scoped>

</style>